import React from 'react';
// react component for creating beautiful carousel
import Carousel from "react-slick";
// material-ui components
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import Card from "components/Card/Card.js";

import mmuImage1 from "assets/img/mmu_johndalton.jpg";
import mmuImage2 from "assets/img/mmu_allsaints.jpg";
import mmuImage3 from "assets/img/Eddie.jpg";

import firebrandImage from "assets/img/firebrand.JPG";
import firebrandImage2 from "assets/img/firebrand2.JPG";
import firebrandImage3 from "assets/img/firebrand3.JPG";

export default class Education extends React.Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };
      return (
<div>
   <h2>Manchester Metropolitan University</h2>
   <h3>BSc(Hons) Software Engineering</h3>
   <h6>Attended from 2011 to 2014</h6>
   <h5>The dissertation was part of a group project that focused on the implementation of the {" "}
      <a href="https://en.wikipedia.org/wiki/Microsoft_Robotics_Developer_Studio" target="_blank" rel="noreferrer">Microsoft Robotics Platform</a>. 
      My role involved the research and development of the Obstacle Avoidance and Pathfinding algorithm, the focus of which was the {" "}
      <a href="https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm" target="_blank" rel="noreferrer">Dijkstra's Shortest Path algorithm</a>.
   </h5>
   <Card>
      <Carousel {...settings}>
         <div>
            <img
               src={mmuImage1}
               alt="First slide"
               className="slick-image"
               />
            <div className="slick-caption">
               <h4>
                  <LocationOn className="slick-icons" />
                  MMU John Dalton Building, Manchester
               </h4>
            </div>
         </div>
         <div>
            <img
               src={mmuImage2}
               alt="Second slide"
               className="slick-image"
               />
            <div className="slick-caption">
               <h4>
                  <LocationOn className="slick-icons" />
                  MMU All Saints Building, Manchester
               </h4>
            </div>
         </div>
         <div>
            <img
               src={mmuImage3}
               alt="Third slide"
               className="slick-image"
               />
            <div className="slick-caption">
               <h4>
                  <LocationOn className="slick-icons" />
                  The "Eddie" Robotics Platform, MMU John Dalton Building (2013)
               </h4>
            </div>
         </div>
      </Carousel>
   </Card>
   <h2>Microsoft Certifications</h2>
<h3>MB2-715 Microsoft Dynamics 365 CE Online Deployment</h3>
<h5>On February 2018, I attended on site training at the Firebrand training centre situated near Wyboston Lakes and was taught by <a href="https://www.linkedin.com/in/richardamajor/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"/> Richard Major</a>.</h5>
<h3>MB2-707 Microsoft Dynamics CRM Customization and Configuration and MB2-701 Extending Dynamics CRM 2013 </h3>
<h5>On April 2016, I travelled to Sweden to attend the Dynamics training at the Firebrand Nordic centre and was taught by <a href="https://www.linkedin.com/in/juliansharp/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"/> Julian Sharp</a>.&nbsp;</h5>
<Card>
      <Carousel {...settings}>
         <div>
            <img
               src={firebrandImage}
               alt="First slide"
               className="slick-image"
               />
            <div className="slick-caption">
               <h4>
                  <LocationOn className="slick-icons" />
                  Firebrand Nordic Centre, Helsingborg, Sweden
               </h4>
            </div>
         </div>
         <div>
            <img
               src={firebrandImage2}
               alt="Second slide"
               className="slick-image"
               />
            <div className="slick-caption">
               <h4>
                  Break during the Dynamics Customisation Course
               </h4>
            </div>
         </div>
         <div>
            <img
               src={firebrandImage3}
               alt="Third slide"
               className="slick-image"
               />
            <div className="slick-caption">
               <h4>
                  <LocationOn className="slick-icons" />
                  View from the Firebrand Nordic Centre, Helsingborg, Sweden
               </h4>
            </div>
         </div>
      </Carousel>
   </Card>
</div>
 );
    }
  }
