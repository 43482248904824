import React from 'react';
// import core components
import Carousel from "react-slick";
import Card from "components/Card/Card.js";

import pc from "assets/img/pc.JPG";
import pc2 from "assets/img/pc2.jpg";
import pc3 from "assets/img/pc3.jpg";
import tech from "assets/img/tech.jpg";

import cycling from "assets/img/cycling.jpg";
import cycling1 from "assets/img/cycling2.jpg";
import cycling4 from "assets/img/cycling5.jpg";

import gym from "assets/img/gym.png";
import gym2 from "assets/img/gym2.png";
import food from "assets/img/mealprep.png";

export default class Hobbies extends React.Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };
      return (
        <div className={this.props.textClassName}>
        <h2>Technology</h2>
        <h5>I thoroughly enjoy building computers and following the industry to keep up to date on the latest advancements.</h5>
        <Card>
      <Carousel {...settings}>
         <div>
            <img
               src={pc}
               alt="First slide"
               className="slick-image"
               />
             <div className="slick-caption">
               <h4>
                  My computer
               </h4>
            </div>
         </div>
         <div>
            <img
               src={pc2}
               alt="Second slide"
               className="slick-image"
               />
              <div className="slick-caption">
               <h4>
                  An older computer of mine, but love this photo!
               </h4>
               </div>
         </div>
         <div>
            <img
               src={pc3}
               alt="Third slide"
               className="slick-image"
               />
               <div className="slick-caption">
               <h4>
                  A computer I built for a friend
               </h4>
               </div>
         </div>
         <div>
            <img
               src={tech}
               alt="Fourth slide"
               className="slick-image"
               />
              <div className="slick-caption">
               <h4>
                  Working with television components of old
               </h4>
               </div>
         </div>
      </Carousel>
   </Card>
   <h3>Cycling</h3>
   <h5>Love getting out on the bike when the weather permits for either Mountain or Road cycling.</h5>
   <Card>
      <Carousel {...settings}>
         <div>
            <img
               src={cycling}
               alt="First slide"
               className="slick-image"
               />
         </div>
         <div>
            <img
               src={cycling1}
               alt="Second slide"
               className="slick-image"
               />
         </div>
         <div>
            <img
               src={cycling4}
               alt="Third slide"
               className="slick-image"
               />
         </div>
      </Carousel>
   </Card>
      <h3>Weightlifting</h3>
      <Card>
      <Carousel {...settings}>
         <div>
            <img
               src={gym}
               alt="First slide"
               className="slick-image"
               />
             <div className="slick-caption">
               <h4>
                  The dream, an empty gym!
               </h4>
            </div>
         </div>
         <div>
            <img
               src={gym2}
               alt="Second slide"
               className="slick-image"
               />
              <div className="slick-caption">
               <h4>
                  Close grip bench press
               </h4>
               </div>
         </div>
         <div>
            <img
               src={food}
               alt="Third slide"
               className="slick-image"
               />
               <div className="slick-caption">
               <h4>
                  Meal prep
               </h4>
               </div>
         </div>
      </Carousel>
   </Card>
      </div>
      );
    }
  }
