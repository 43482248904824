import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ConnectSection() {
  const classes = useStyles();
  return (
    <a name="connect">
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Connect</h2>
          <h5 className={classes.description}>
            Please connect with me on social media, as I am always looking to expand my network for new and refreshing ideas!
          </h5>
        </GridItem>
      </GridContainer>
      <div>
                   <Button justIcon link className={classes.socials} 
                   href="https://www.instagram.com/rhodes.tech/" target="_blank">
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.socials}
                    href="https://twitter.com/rhodestech" target="_blank">
                      <i className={"fab fa-twitter"} />
                    </Button>             
                    <Button justIcon link className={classes.socials}
                    href="https://www.youtube.com/c/rhodestech" target="_blank">
                      <i className={"fab fa-youtube"} />
                    </Button>
                    <Button justIcon link className={classes.socials}
                    href="https://www.linkedin.com/in/matthew-rhodes/" target="_blank">
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button justIcon link className={classes.socials}
                    href="https://steamcommunity.com/id/rhodestech" target="_blank">
                      <i className={"fab fa-steam"} />
                    </Button>
      </div>
    </div>
    </a>
  );
}
