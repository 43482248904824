import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import HomePage from "views/HomePage/HomePage.js";
import Links from "views/LinksPage/LinksPage.js";
import DefaultPage from "views/DefaultPage/DefaultPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    <Route path="/links">
        <Links />
      </Route>  
      <Route path="/">
           <HomePage />
      </Route>  
    </Switch>
  </Router>,
  document.getElementById("root")
);
