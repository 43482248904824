import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import RTHeader from "components/Header/RTHeader.js";
import RTFooter from "components/Footer/RTFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Video from "components/Video/Video.js";
import RTHeaderLinks from "components/Header/RTHeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import AboutSection from "./Sections/AboutSection.js";
import PortfolioSection from "./Sections/PortfolioSection.js";
import ConnectSection from "./Sections/ConnectSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <RTHeader
        logo={require("assets/img/logo_white.png")}
        altLogo={require("assets/img/logo_black.png")}
        brand=""
        rightLinks={<RTHeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "dark"
        }}
        {...rest}
      />
      <a name="top"></a>
      <Parallax>
        <Video  videoUrl={require("assets/video/code.mp4")} style={{position: "absolute",
    width: "100%"}}>
        </Video>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div id="about" className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <AboutSection/>
        <PortfolioSection />
        <ConnectSection />
        </div>
      </div>
      <RTFooter />
    </div>
  );
}
