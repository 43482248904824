import React from 'react';
import Logo from '../../assets/img/logo_white.png';
import Twitter from '../../assets/img/twitter_white.png';
import Instagram from '../../assets/img/instagram_white.png';
import Youtube from '../../assets/img/youtube_white.png';
import LinkedIn from '../../assets/img/linkedin_white.png';
import Steam from '../../assets/img/steam_white.png';
import LTT from '../../assets/img/ltt_white.png';
import me from '../../assets/img/profile.png';

import LinkItem from '../../components/LinkItem/LinkItem.js';
import Profile from '../../components/Profile/Profile.js'

function LinksPage() {
  const containerStyle = {
    width: "75%",
    display: "inline-block",
    textAlign: "center",
  };
  return (
  <div class="App" style={{textAlign: 'center'}}>
    <div style={containerStyle}>
      <Profile imageSrc={me} alt="Matthew Rhodes" />
      <LinkItem imageSrc={Logo} url="https://rhodestech.co.uk" alt="Rhodes Tech Website"/>
      <LinkItem url="https://www.instagram.com/rhodes.tech/" icon="fab fa-instagram fa-4x"/>
      <LinkItem url="https://twitter.com/rhodestech" icon="fab fa-twitter fa-4x"/>
      <LinkItem url="https://www.youtube.com/c/rhodestech" icon="fab fa-youtube fa-4x"/>
      <LinkItem url="https://www.linkedin.com/in/matthew-rhodes/" icon="fab fa-linkedin fa-4x"/>
      <LinkItem url="https://steamcommunity.com/id/rhodestech" icon="fab fa-steam fa-4x"/>
      <LinkItem imageSrc={LTT} url="https://linustechtips.com/profile/18831-rhodestech/" alt="Rhodes Tech LTT forum" />
    </div>
  </div>
  );
}

export default LinksPage;