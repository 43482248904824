import React from 'react';
import '../LinkItem/LinkItem.css';

class LinkItem extends React.Component {
    render() {
      return (
        <a href={this.props.url} target="_blank" rel="noreferrer">
           <div class="linkItem" style={this.props.style}>
           <span class="helper"></span>
                    <img src={this.props.imageSrc} alt={this.props.alt} display="block" width="auto" height="50px"/>
                    <i className={this.props.icon} />
            </div>
        </a>
      );
    }
  }
  export default LinkItem;