import React from 'react';
// react component for creating beautiful carousel
import Carousel from "react-slick";
// material-ui components

// core components
import Card from "components/Card/Card.js";

import crmcsImage from "assets/img/crmcs.jpg";
import crmcsImage1 from "assets/img/crmcs1.jpg";
import crmcsImage2 from "assets/img/crmcs2.jpg";

class Profile extends React.Component {
    render(){
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };
      return (
<div>
   <h2><a href="http://crmcs.co.uk" target="_blank" rel="noreferrer">CRMCS</a></h2>
   <h3>Dynamics 365 Developer</h3>
   <h6>June 2015 to Present</h6>
   <dl>
  <dt>• Extend the functionality of Dynamics using C#, JavaScript and HTML in conjunction with "out of the box" tools such as; Workflows, Business Rules, Sql Server Reporting Services (SSRS), Entity and Form customisation.</dt>
  <dt>• Perform in a client-facing function for the entirety of the business process lifecycle:</dt>
  <dd>- Capture requirements for new and existing processes.</dd>
  <dd>- Implementation using the most viable approach to achieve the particular aims in a timely manner.</dd>
  <dd>- Supply regular milestone updates through deliverable updates and video conference calls.</dd>
  <dd>- Create training documentation in order to enable the users to utilize the process effectively in a manner that meets the client's expectations.</dd>
  <dd>- Maintaining (break/fix) or extending the current process as the needs of the client change over time.</dd>
<dt>• Support the development of bespoke applications for Dynamics. </dt>
<dt>• Manage change using Agile methodology and experience of the Waterfall model.</dt>
</dl>
<Card>
      <Carousel {...settings}>
         <div>
            <img
               src={crmcsImage}
               alt="First slide"
               className="slick-image"
               />
         </div>
         <div>
            <img
               src={crmcsImage1}
               alt="Second slide"
               className="slick-image"
               />
         </div>
         <div>
            <img
               src={crmcsImage2}
               alt="Third slide"
               className="slick-image"
               />
         </div>
      </Carousel>
   </Card>
   </div>
   
         );
    }
  }
  export default Profile;