import React from 'react';

class Video extends React.Component {
    render() {
      return (
        <video preload loop muted autoPlay style={this.props.style}>
           <source src={this.props.videoUrl} type="video/mp4" />
       </video>
      );
    }
  }
  export default Video;