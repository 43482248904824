import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import WorkIcon from "@material-ui/icons/Work";
import School from "@material-ui/icons/School";
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Education from "components/Profile/Education.js";
import Work from "components/Profile/Work.js";
import Hobbies from "components/Profile/Hobbies.js";

import profile from "assets/img/profile.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function AboutSection(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
        <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>  
                    <img src={profile} alt="..." className={imageClasses} />                
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Matthew Rhodes</h3>
                    <h6>Software Engineer</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
              I am a software engineer based in Manchester, UK with over five years in the field. I specialise in .NET technologies, Dynamics 365 CRM, Azure and system integration. 
              I have always been fascinated by software and technology since the age of 7 when my father taught me how to build computers and write simple programs using Visual Basic 6.
              </p>
            </div>     
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="info"
                  tabs={[
                    {
                      tabButton: "Experience",
                      tabIcon: WorkIcon,
                      tabContent: (
                        <div className={classes.description}>
                        <Work />
                      </div> 
                      )
                    },
                    {
                      tabButton: "Education",
                      tabIcon: School,
                      tabContent: (
                        <div className={classes.description}>   
                           <Education />
                         </div>
                      )
                    },
                    {
                      tabButton: "Interests",
                      tabIcon: Favorite,
                      tabContent: (
                        <Hobbies className={navImageClasses} textClassName={classes.description}/>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
  );
}
