import React from 'react';

export default class Profile extends React.Component {
    render() {
      const style = {
        marginTop: "10px",
        marginBottom: "20px",
        height: "150px",
        width: "130px",
        border: "2px solid white",
        borderRadius: "50%"
      };

      return (
          <img src={this.props.imageSrc} style={style} width='130' height='150' alt={this.props.alt}></img>
      );
    }
  }
  